import React from 'react';

function Header() {
    return(
            <div className="header">
                <nav>
                    <ul>
                        {/* <li><a href="/songs" className="glass-button">楽曲一覧</a></li> */}
                    </ul>
                </nav>
            </div>
    )
}

export default Header;
