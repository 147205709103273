import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Songs() {
    const [songs, setSongs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('https://diphyleia.com/api/songs')  // バックエンドAPIのURL
            .then(response => response.json())
            .then(data => {
                setSongs(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('データ取得エラー: ', error);
                setLoading(false);
            });
    }, []);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // ユーザーエージェントをチェック
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobileDevice = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

        setIsMobile(isMobileDevice);
    }, []);

    const [searchQuery, setSearchQuery] = useState('');
    const [artists, setArtists] = useState([]);
    const [selectedArtist, setSelectedArtist] = useState('');
    const [sortOrder, setSortOrder] = useState('');

    useEffect(() => {
        // 全アーティスト名を取得するAPIリクエスト
        axios.get('https://diphyleia.com/api/artists')
            .then((response) => {
                setArtists(response.data);
            })
            .catch((error) => {
                console.error('サーバーエラーが発生しました:', error.response ? error.response.data : error.message);
                // 必要であればUIにエラーメッセージを表示
            });
    }, []);

    const handleSearch = () => {
        axios.get('https://diphyleia.com/api/songs/search', {
            params: {
                query: searchQuery,
                artist: selectedArtist,
                sort: sortOrder,
            }
        }).then((response) => {
            setSongs(response.data);
        }).catch((error) => {
            console.error('検索エラー:', error);
        });
    };

    if (loading) {
        return <div>読み込み中...</div>;
    }

    return (
        <div>
            <h1>楽曲一覧</h1>
            {isMobile ? (
                //モバイル用
                <><div className='form-panel-mobile'>
                    <div className='keyword-row'>
                        <label>キーワード</label>
                    </div>
                    <div className='text-row'>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)} />
                    </div>
                    <div className='button-row'>
                        <button onClick={handleSearch}>検索</button>
                    </div>

                    <div className='artist_label-row'>
                        <label>アーティスト</label>
                    </div>
                    <div className='artist_input-row'>
                        <select
                            value={selectedArtist}
                            onChange={(e) => setSelectedArtist(e.target.value)}
                        >
                            <option value="">全てのアーティスト</option>
                            {artists.map((artist, index) => (
                                <option key={index} value={artist.artist}>{artist.artist}</option>
                            ))}
                        </select>
                    </div>

                    <div className='sort_label-row'>
                        <label>表示順</label>
                    </div>
                    <div className='sort_input-row'>
                        <select
                            value={sortOrder}
                            onChange={(e) => setSortOrder(e.target.value)}
                        >
                            <option value="">選択してください</option>
                            <option value="title_asc">タイトル昇順</option>
                            <option value="title_desc">タイトル降順</option>
                            <option value="artist_asc">アーティスト昇順</option>
                            <option value="artist_desc">アーティスト降順</option>
                            <option value="release_asc">リリース日昇順</option>
                            <option value="release_desc">リリース日降順</option>
                        </select>
                    </div>
                </div>
                    <div className="container-mobile">
                        <table className="radius-table child glass-panel">
                            <thead>
                                <tr>
                                    <th>タイトル</th>
                                    <th>アーティスト</th>
                                </tr>
                            </thead>
                            <tbody>
                                {songs.map(song => (
                                    <tr key={song.id}>
                                        <td data-label="Title">{song.title}</td>
                                        <td data-label="Artist">{song.artist}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div></>
            ) : (<><div className='form-panel'>
                <div className='search-row'>
                    <label>キーワード</label>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)} />
                    <button onClick={handleSearch}>検索</button>
                </div>

                <div className='filter-row'>
                    <label>アーティスト</label>
                    <select
                        value={selectedArtist}
                        onChange={(e) => setSelectedArtist(e.target.value)}
                    >
                        <option value="">全てのアーティスト</option>
                        {artists.map((artist, index) => (
                            <option key={index} value={artist.artist}>{artist.artist}</option>
                        ))}
                    </select>

                    <label>表示順</label>
                    <select
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value)}
                    >
                        <option value="">選択してください</option>
                        <option value="title_asc">タイトル昇順</option>
                        <option value="title_desc">タイトル降順</option>
                        <option value="artist_asc">アーティスト昇順</option>
                        <option value="artist_desc">アーティスト降順</option>
                        <option value="release_asc">リリース日昇順</option>
                        <option value="release_desc">リリース日降順</option>
                    </select>
                </div>
            </div><div className="container">
                    <table className="radius-table child glass-panel">
                        <thead>
                            <tr>
                                <th>タイトル</th>
                                <th>アーティスト</th>
                                <th>アルバム</th>
                                <th>ジャンル</th>
                                <th>リリース日</th>
                                <th>長さ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {songs.map(song => (
                                <tr key={song.id}>
                                    <td data-label="Title">{song.title}</td>
                                    <td data-label="Artist">{song.artist}</td>
                                    <td data-label="Album">{song.album}</td>
                                    <td data-label="Genre">{song.genre}</td>
                                    <td data-label="Release">{song.release_date.slice(0, 10)}</td>
                                    <td data-label="Duration">{song.duration.slice(3, 8)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div></>
            )}
        </div>
    );
}

export default Songs;