import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Songs from './components/Songs';
import Header from './components/Header';

function App() {
  return (
    <div className="image">
    <Header />
    <Router>
      <div className="App">
        <Routes>
          <Route path="/songs" element={<Songs />} />
        </Routes>
      </div>
    </Router>
    </div>
  );
}

export default App;
